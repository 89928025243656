// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn {
  position: relative;
  white-space: nowrap;
  font-family: var(--sans-serif);
  font-weight: 300;
  font-size: 18px;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
}

.btn:hover {
  color: white;
  box-shadow: var(--box-shadow);
  transition: all 0.1s;
  -webkit-transition: all 0.1s;
}

.rect-btn {
  background-color: var(--dark-blue);
  width: 175px;
  margin: auto;
  padding: 1.25vmin;
  border-radius: 4px;
  color: #fff;
}

.rect-btn:hover {
  background-color: var(--violet);
}

.rounded-btn {
  background: var(--dark-blue);
  width: calc(200px + 6 * (100vw - 320px) / 680);
  padding: 5px 20px 5px 20px;
  margin: 5px;
  border: none;
  border-radius: 20px;
  color: white;
}

.rounded-btn:hover {
  background-color: var(--violet);
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/Buttons/Buttons.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,mBAAA;EACA,8BAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;EACA,qBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,YAAA;EACA,6BAAA;EACA,oBAAA;EACA,4BAAA;AACJ;;AAEA;EACI,kCAAA;EACA,YAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,WAAA;AACJ;;AAGA;EACI,+BAAA;AAAJ;;AAGA;EACI,4BAAA;EACA,8CAAA;EACA,0BAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,YAAA;AAAJ;;AAGA;EACI,+BAAA;AAAJ","sourcesContent":[".btn {\n    position: relative;\n    white-space: nowrap;\n    font-family: var(--sans-serif);\n    font-weight: 300;\n    font-size: 18px;\n    overflow: hidden;\n    cursor: pointer;\n    text-decoration: none;\n    text-align: center;\n}\n\n.btn:hover {\n    color: white;\n    box-shadow: var(--box-shadow);\n    transition: all 0.1s;\n    -webkit-transition: all 0.1s;\n}\n\n.rect-btn {\n    background-color: var(--dark-blue);\n    width: 175px;\n    margin: auto;\n    padding: 1.25vmin;\n    border-radius: 4px;\n    color: #fff;\n\n}\n\n.rect-btn:hover {\n    background-color: var(--violet);\n}\n\n.rounded-btn {\n    background: var(--dark-blue);\n    width: calc(200px + (6 * ((100vw - 320px) / 680)));\n    padding: 5px 20px 5px 20px;\n    margin: 5px;\n    border: none;\n    border-radius: 20px;\n    color: white;\n}\n\n.rounded-btn:hover {\n    background-color: var(--violet);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
