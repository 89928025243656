import React, { useEffect, useState } from "react";
import useUser from "../../../hooks/useUser";
import usePrevious from "../../../hooks/usePrevious";
import { Checkbox } from "@fluentui/react/lib/Checkbox";
import { Calendar, PrimaryButton } from "@fluentui/react";
import { SubscribeSS } from "../../../actions/Subscriptions";

const ManualPaidValue = 4;

const ScholarSiftSubscriptionInfo = (props) => {
  console.log(props);
  const { user, saveUser } = useUser();
  const [allowSubscribe, setAllowSubscribe] = useState(false);
  const [exp, setExp] = useState(
    props.expiration && props.expiration.toString().indexOf("Z") === -1
      ? props.expiration * 1000
      : undefined
  );
  const [update, setUpdate] = useState(false);
  const prevExp = usePrevious(exp);

  useEffect(() => {
    let v =
      props.expiration && props.expiration.toString().indexOf("Z") === -1
        ? props.expiration * 1000
        : undefined;
    if (prevExp !== v) {
      setExp(v);
      setUpdate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.expiration]);

  useEffect(() => {
    let pv = prevExp ? new Date(prevExp).getTime() : undefined;
    let ev = exp ? new Date(exp).getTime() : undefined;
    console.log(ev + " " + pv);
    if (exp && ev !== pv) setUpdate(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exp]);

  const Subscribe = () => {
    if (exp && exp !== "0") {
      console.log("Subscribing...");
      let p = { ...props };
      delete p.setProfile;
      SubscribeSS(
        user,
        saveUser,
        props.id,
        ManualPaidValue,
        exp.getTime() / 1000,
        () => {
          props.setProfile({ ...p, expiration: exp.toLocaleString() });
        }
      );
    }
  };

  console.log(exp);
  console.log(update + " " + allowSubscribe);

  if (exp === undefined && !allowSubscribe)
    return (
      <div className="subscription-panel">
        <div className="subscription-title">
          ScholarSift Manual Subscription Info
        </div>
        <div className="subscription-exp-wrapper">
          <PrimaryButton
            text="Add Manual Subscription"
            onClick={() => {
              setAllowSubscribe(true);
            }}
          />
        </div>
      </div>
    );

  return (
    <div className="subscription-panel">
      <div className="subscription-title">
        ScholarSift Manual Subscription Info
      </div>
      <div className="subscription-exp-wrapper">
        <div className="subscription-exp">
          Subscription Expiration: {exp ? new Date(exp).toLocaleString() : ""}
        </div>
        <Calendar
          showGoToToday={true}
          value={exp ? new Date(exp) : undefined}
          onSelectDate={setExp}
        />
      </div>

      {update ? (
        <PrimaryButton text="Apply" onClick={Subscribe} />
      ) : (
        <Checkbox
          className="nocheck"
          checked={exp && new Date() > exp}
          label="Expired"
        />
      )}
    </div>
  );
};

export default React.memo(ScholarSiftSubscriptionInfo);
