import { APIErrorHandler } from '../../../../actions/APIErrorHandler';
import axios from 'axios';

export const fetchItems = async (user, saveUser, setItems, toggleFetchingItems) => {
      axios
        .post("/api/lrsubmissions", {profile: user.current.scholarsift.profile},
          {
            headers: {
              Authorization: "Bearer " + user.current.token.id,
              Coda: user.current.scholarsift.coda,
            },
          }
        )
        .then((response) => {
          if (response.data.submissions)
            setItems(response.data.submissions);
          else {
            console.log(response)
          }
        })
        .catch((err) => {
          APIErrorHandler(err, user, saveUser, (v) => { fetchItems(user, saveUser, setItems, toggleFetchingItems); });
        });
}

export const fetchEditors = async (user, saveUser, setEditors, toggleFetchingEditors) => {
    setEditors(["Trent", "Haka"]);
    toggleFetchingEditors();
}

export const fetchTags = async (user, saveUser, setTags, toggleFetchingTags) => {
    setTags(["Awesome", "Mid", "Shiite"])
    toggleFetchingTags();
}

export const GetNewMessagCounts = (user, saveUser, setNMC) => {
  var p = user.current.scholarsift.profile;
  axios
      .post("/api/lsmsg", {profile: p},
        {
          headers: {
            Authorization: "Bearer " + user.current.token.id,
            Coda: user.current.scholarsift.coda,
          },
        }
      )
      .then((response) => {
          var arr = [];
          response.data.forEach(msg => {
              var key = msg.thread.startsWith(p + "-") ? "lend" : "pend";
              var nm = 0;
              for(var i = msg[key] + 1; i < msg.parts.length; i++) {
                  var h = JSON.parse(msg.parts[i].header)
                  if(h.from !== p) {
                      ++nm;
                      break;
                  }
              }
              arr.push({id: msg.thread.substring(msg.thread.indexOf("-") + 1), ct: nm})
          })
          setNMC(arr);
      })
      .catch((err) => {
        APIErrorHandler(err, user, saveUser, () => {GetNewMessagCounts(user, saveUser, setNMC)  });
      });
}