import axios from 'axios';
import { APIErrorHandler } from './APIErrorHandler';

export const SubscribeSS = (user, saveUser, id, paid, expiration, callBack) => {
    axios
        .post("/api/ssubscribe", {id: id, paid: paid, expiration: expiration}, {
            headers : {  
                    "Authorization":"Bearer " + user.current.token.id,
                    "Coda" : user.current.scholarsift.coda
            }
        })
        .then(response => {
            callBack(response.data)
        })
        .catch(error => APIErrorHandler(error, user, saveUser, v => v ? console.log(v) : SubscribeSS(user, saveUser, id, paid, expiration, callBack)));
}

