import axios from 'axios';
import { APIErrorHandler } from '../../../actions/APIErrorHandler';

export const GetMessageCount = (user, saveUser, setNMessages) => {
    var p = user.current.scholarsift.profile;
    axios
        .post("/api/lsmsg", {profile: p},
          {
            headers: {
              Authorization: "Bearer " + user.current.token.id,
              Coda: user.current.scholarsift.coda,
            },
          }
        )
        .then((response) => {
            var nm = 0;
            response.data.forEach(msg => {
                var key = msg.thread.startsWith(p + "-") ? "lend" : "pend";
                for(var i = msg[key] + 1; i < msg.parts.length; i++) {
                    var h = JSON.parse(msg.parts[i].header)
                    if(h.from !== p) {
                        ++nm;
                        break;
                    }
                }
            })
            setNMessages(nm);
        })
        .catch((err) => {
          APIErrorHandler(err, user, saveUser, () => { GetMessageCount(user, saveUser, setNMessages) });
        });
}