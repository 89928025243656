import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ViewPort from "../../Layout/ViewPort";
import useUser from '../../../hooks/useUser';
import useLocationState from '../../../hooks/useLocationState';
import { LoadingAnimation, ManageSubmissions, defaultGroupPrefs, defaultPagePrefs } from "ss-lib";
import { fetchEditors, fetchItems, fetchTags } from './actions';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export const SubmissionPortal = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { user, saveUser, setTitle } = useUser();
    const [groupPrefs, setGroupPrefs] = useState(user.current.scholarsift.groupPrefs ? user.current.scholarsift.groupPrefs : {...defaultGroupPrefs})
    const [pagePrefs, setPagePrefs] = useState(user.current.scholarsift.pagePrefs && user.current.scholarsift.pagePrefs[location.pathname] ? user.current.scholarsift.pagePrefs[location.pathname] : {...defaultPagePrefs});

    const [items, setItems] = useState();
    const [fetchingItems, setFetchingItems] = useState(false);
    const [editors, setEditors] = useState();
    const [fetchingEditors, setFetchingEditors] = useState(false);
    const [tags, setTags] = useState();
    const [fetchingTags, setFetchingTags] = useState(false);
    const { state } = useLocationState();
    const h = useRef();

    useEffect(() => {
        setTitle("Manage Submissions");
        document.title = "Manage Submissions";
    })

    useEffect(()=> {
        if(items === undefined && !fetchingItems) {
            console.log("Fetching Items")
            setFetchingItems(true);
            fetchItems(user, saveUser, (v) => {setItems(v); setFetchingItems(false)}, () => { setFetchingItems(false)})
        }
        if(editors === undefined && !fetchingEditors) {
            setFetchingEditors(true);
            fetchEditors(user, saveUser, setEditors, () => { setFetchingItems(false)})
        }
        if(editors === undefined && !fetchingTags) {
            setFetchingTags(true);
            fetchTags(user, saveUser, setTags, () => { setFetchingItems(false)})
        }
    }, [location.pathname])

    useEffect(() => {
        let v = state.viewport.h - (state.viewport.m + state.viewport.f + state.viewport.o);
        if (v !== h.current) h.current = v;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.viewport]);

    const toggleControls = () => {
        setPagePrefs({...pagePrefs, showControls: !pagePrefs.showControls})
    }

    const handleItemClick = v => {
        if(v.key === "analyze")
            navigate("/analyze", v.item);
        else if(v.key === "messages")
            navigate("/messages", {state:{header:{from:user.current.scholarsift.profile, to: v.item.to}, title: v.item.title, thread: user.current.scholarsift.profile + "-" + v.item.id}})
        var offset = new Date().getTimezoneOffset();    
    }

    return  <ViewPort>
                <div style={{ height: h.current}}>
                {
                    items === undefined || editors === undefined || tags === undefined ?
                    <LoadingAnimation msg="Loading" /> :
                    <ManageSubmissions
                        prefs={groupPrefs}
                        pagePrefs={pagePrefs}
                        width={state.viewport.w}
                        height={h.current - 32}
                        manuscripts={items}
                        editors={editors}
                        tags={tags}
                        handleItemClick={handleItemClick}
                        toggleControls={toggleControls}
                    />
                }
                </div> 
            </ViewPort>

}

export default SubmissionPortal;