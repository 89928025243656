import { useMemo } from 'react'
import { useNavigate } from "react-router-dom";
import { useBoolean } from "@fluentui/react-hooks";
import { Panel, Nav, Stack, Rectangle } from "@fluentui/react";
import {
  GlobalNavButtonIcon,
  LeaveUserIcon,
  ContactIcon,
} from "@fluentui/react-icons-mdl2";
import useUser from "../../hooks/useUser";
import RectButton from "../Layout/Buttons/RectButton";
import "./Nav.scss";
import { Debug } from 'ss-lib';
import { CounterBadge } from '@fluentui/react-components';

const MenuPanel = ({
  isAuth,
  isAdmin,
  isLawReview,
  isGroupAdmin,
  type,
  signOut,
  user,
  isCollapsed,
  darkMode,
}) => {
  const navigate = useNavigate();
  const { allowRoute, nMessages } = useUser();
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  const handleOpen = (e) => {
    e.preventDefault();
    openPanel();
  };

  const navStyle = {
    linkText: { fontSize: 16 },
  };

  const navGroups = useMemo(() => {
    const paid = isAuth && user.scholarsift.paid === 1;

    let isJunior =
      user.scholarsift.limits && user.scholarsift.limits.length > 0;
    let navItems = [];
    var links = [];

    const getPaid = paid ? "Subscription" : "Subscribe";

    if(!allowRoute("lr") && !allowRoute("admin")) {

      /*
      Profile
Analyze
Preprints
My Papers
Faculty Bios
Tutorials
FAQs
About
Contact
Home
      */
      let links = [
          {
            name: "Profile",
            key: "profile",
            icon: "AccountManagement",
            forceAnchor: true,
            onClick: () => {
              dismissPanel();
              navigate("/profile");
            },
          },
          {
            name: "Analyze",
            key: "analyze",
            icon: "DiagnosticDataBarTooltip",
            forceAnchor: true,
            onClick: () => {
              dismissPanel();
              navigate("/analyze");
            },
          },
          {
            key: "preprints",
            name: "Preprints",
            icon: "PageEdit",
            forceAnchor: true,
            onClick: () => {
              dismissPanel();
              navigate("/preprints");
            },
          },
          {
            key: "papers",
            name: "My Papers",
            icon: "PageEdit",
            forceAnchor: true,
            onClick: () => {
              dismissPanel();
              navigate("/papers");
            },
          },
          {
            key:"messages",
            name: nMessages ? "Messages (" + nMessages + ")": "Messages",
            icon:"Mail",
            forceAnchor: true,
            onClick: () => {
              dismissPanel();
              navigate("/messages");
            }
          },
          {
            key: "about",
            name: "About",
            icon: "StatusCircleQuestionMark",
            forceAnchor: true,
            onClick: () => {
              dismissPanel();
              navigate("/about");
            },
          },
          {
            key: "contact",
            name: "Contact",
            icon: "ContactCard",
            forceAnchor: true,
            onClick: () => {
              dismissPanel();
              navigate("/contact");
            },
          },
          {
            key: "welcome",
            name: "Welcome",
            icon: "Home",
            forceAnchor: true,
            onClick: () => {
              dismissPanel();
              navigate("/welcome");
            },
          },
        ]

        if(Debug()) {
          links.splice(4, 0, 
            {
              name: "Submit",
              key: "submit",
              icon: "Upload",
              forceAnchor: true,
              onClick: () => {
                dismissPanel();
                navigate("/submit");
              },
            },
            {
              name: "Manage Submissions",
              key: "submissions",
              icon: "TaskManager",
              forceAnchor: true,
              onClick: () => {
                dismissPanel();
                navigate("/submissions");
              },
            }
          )
        }

        return [{links}];
    }

    if (!isLawReview && !isAdmin && !isGroupAdmin && !isJunior && isAuth) {
      /*
      navItems.push({
        name: "Subscription",
        expandAriaLabel: "Expand Admin menu section",
        collapseAriaLabel: "Collapse Admin menu section",
        collapseByDefault: true,
        links: [
          {
            key: "payment",
            icon: "UserFollowed",
            name: getPaid,
            forceAnchor: true,
            onClick: () => {
              dismissPanel();
              navigate("/subscribe");
            },
          },
        ],
      });
      */
    }

    if (isAuth) {
      //Law Reviews...
      if (isLawReview) {
        links.push({
          name: "Manage Submissions",
          key: "list",
          icon: "TaskManager",
          forceAnchor: true,
          onClick: () => {
            dismissPanel();
            navigate("/portal/list");
          },
        });
        links.push({
          name: "Upload",
          key: "upload",
          icon: "Upload",
          forceAnchor: true,
          onClick: () => {
            dismissPanel();
            navigate("/portal/upload");
          },
        });

        //Do not show management if limited editor
        if (
          !user.scholarsift.limits ||
          user.scholarsift.limits["manage"] ||
          isAdmin
        ) {
          links.splice(1, 0, {
            name: "Manage Editors",
            key: "editors",
            icon: "AccountActivity",
            forceAnchor: true,
            onClick: () => {
              dismissPanel();
              navigate("/portal/editors");
            },
          });
        }
        navItems.push({
          name: "Submission Portal",
          expandAriaLabel: "Expand SubmissionPortal section",
          collapseAriaLabel: "Collapse SubmissionPortal section",
          collapseByDefault: isAdmin,
          links: links,
        });
      }

      let uLinks = [
        {
          name: "Analyze",
          key: "analyze",
          icon: "DiagnosticDataBarTooltip",
          forceAnchor: true,
          onClick: () => {
            dismissPanel();
            navigate("/analyze");
          },
        },
        {
          name: "Profile",
          key: "profile",
          icon: "AccountManagement",
          forceAnchor: true,
          onClick: () => {
            dismissPanel();
            navigate("/profile");
          },
        },
      ];

      if (isAdmin || (!isJunior && !isLawReview)) {
        uLinks.push({
          key: "preprints",
          name: "Preprints",
          icon: "PageEdit",
          forceAnchor: true,
          onClick: () => {
            dismissPanel();
            navigate("/preprints");
          },
        });
        uLinks.push({
          key: "papers",
          name: "My Papers",
          icon: "PageEdit",
          forceAnchor: true,
          onClick: () => {
            dismissPanel();
            navigate("/papers");
          },
        });
      }

      if (isJunior) {
        uLinks.splice(0, 0, {
          name: "To Do",
          key: "todo",
          icon: "TaskSolid",
          forceAnchor: true,
          onClick: () => {
            dismissPanel();
            navigate("/todo");
          },
        });
      }

      navItems.push({
        name: "User Menu",
        icon: "AccountManagement",
        expandAriaLabel: "Expand User Menu section",
        collapseAriaLabel: "Collapse User section",
        collapseByDefault: true,
        links: uLinks,
      });
    }

    
    if (Debug() && ((isAuth && !isLawReview && !isJunior) || isAdmin)) {
      navItems.push({
        name: "Submissions",
        expandAriaLabel: "Expand Submissions section",
        collapseAriaLabel: "Collapse Submissions section",
        collapseByDefault: true,
        links: [
          {
            name: "Submit",
            key: "submit",
            icon: "Upload",
            forceAnchor: true,
            onClick: () => {
              dismissPanel();
              navigate("/submit");
            },
          },
          {
            name: "Manage Submissions",
            key: "submissions",
            icon: "TaskManager",
            forceAnchor: true,
            onClick: () => {
              dismissPanel();
              navigate("/submissions");
            },
          },
        ],
      });
    }

    if (isAdmin) {
      navItems.push({
        name: "Admin Menu",
        expandAriaLabel: "Expand Admin menu section",
        collapseAriaLabel: "Collapse Admin menu section",
        collapseByDefault: false,
        links: [
          {
            name: "Dashboard",
            expandAriaLabel: "Expand Admin dashboard section",
            collapseAriaLabel: "Collapse Admin dashboard section",
            collapseByDefault: false,
            links: [
              {
                key: "accounts",
                name: "Accounts",
                icon: "ProfileSearch",
                forceAnchor: true,
                onClick: () => {
                  dismissPanel();
                  navigate("/dashboard/accounts");
                },
              },
              {
                key: "crm",
                name: "CRM",
                icon: "ProfileSearch",
                forceAnchor: true,
                onClick: () => {
                  dismissPanel();
                  navigate("/dashboard/crm");
                },
              },
              {
                key: "groups",
                name: "Groups",
                icon: "Group",
                forceAnchor: true,
                onClick: () => {
                  dismissPanel();
                  navigate("/dashboard/groups");
                },
              },
              {
                key: "journals",
                name: "Journals",
                icon: "Articles",
                forceAnchor: true,
                onClick: () => {
                  dismissPanel();
                  navigate("/dashboard/journals");
                },
              },
              {
                key: "maintenance",
                name: "Maintenance",
                icon: "ConstructionCone",
                forceAnchor: true,
                onClick: () => {
                  dismissPanel();
                  navigate("/dashboard/maintenance");
                },
              },
              {
                key: "usage",
                name: "Usage",
                icon: "AnalyticsReport",
                forceAnchor: true,
                onClick: () => {
                  dismissPanel();
                  navigate("/dashboard/usage");
                },
              },
              {
                key: "system-health",
                name: "System Health",
                icon: "SyncStatus",
                forceAnchor: true,
                onClick: () => {
                  dismissPanel();
                  navigate("/dashboard/system&health");
                },
              },
            ],
          },
          {
            key: "bugs",
            name: "Bugs",
            forceAnchor: true,
            icon: "Bug",
            onClick: () => {
              dismissPanel();
              navigate("/bugs");
            },
          },
          {
            key: "nodes",
            name: "Nodes",
            icon: "BranchFork2",
            forceAnchor: true,
            onClick: () => {
              dismissPanel();
              navigate("/nodes");
            },
          },
        ],
      });
    }

    if (isGroupAdmin) {
      navItems.push({
        name: "Manage",
        expandAriaLabel: "Expand Group Admin Manage section",
        collapseAriaLabel: "Collapse Group Admin Manage section",
        collapseByDefault: true,
        links: [
          {
            key: "papers",
            name: "Papers",
            forceAnchor: true,
            onClick: () => {
              dismissPanel();
              navigate("/manage/papers");
            },
          },
          {
            key: "users",
            name: "Users",
            forceAnchor: true,
            onClick: () => {
              dismissPanel();
              navigate("/manage/users");
            },
          },
          {
            key: "submit",
            name: "Submit a Paper",
            icon: "Upload",
            forceAnchor: true,
            onClick: () => {
              dismissPanel();
              navigate("/manage/submit");
            },
          },
          {
            key: "submissions",
            name: "Submissions",
            icon: "TaskManager",
            forceAnchor: true,
            onClick: () => {
              dismissPanel();
              navigate("/manage/submissions");
            },
          },
        ],
      });
    }

    let linx = [
      {
        name: "Analyze",
        key: "Analyze",
        icon: "DiagnosticDataBarTooltip",
        forceAnchor: true,
        onClick: () => {
          dismissPanel();
          navigate("/analyze");
        },
      },
      {
        key:"messages",
        name: nMessages ? "Messages (" + nMessages + ")": "Messages",
        icon:"Mail",
        forceAnchor: true,
        onClick: () => {
          dismissPanel();
          navigate("/messages");
        }
      },
      {
        name: "Faculty Bios",
        key: "Faculty Bios",
        icon: "ProfileSearch",
        forceAnchor: true,
        onClick: () => {
          dismissPanel();
          navigate("/faculty");
        },
      },
      {
        name: "Home",
        key: "home",
        icon: "Home",
        forceAnchor: true,
        onClick: () => {
          dismissPanel();
          navigate("/");
        },
      },
      {
        name: "About",
        key: "about",
        icon: "Teamwork",
        forceAnchor: true,
        onClick: () => {
          dismissPanel();
          navigate("/about");
        },
      },
      {
        name: "Tutorials",
        key: "How To",
        icon: "D365TalentLearn",
        forceAnchor: true,
        onClick: () => {
          dismissPanel();
          navigate("/tutorials");
        },
      },
      {
        name: "FAQs",
        key: "FAQs",
        icon: "StatusCircleQuestionMark",
        forceAnchor: true,
        onClick: () => {
          dismissPanel();
          navigate("/faqs");
        },
      },
      {
        name: "Contact",
        key: "contact",
        icon: "Mail",
        forceAnchor: true,
        onClick: () => {
          dismissPanel();
          navigate("/contact");
        },
      },
      {
        key: "welcome",
        name: "Welcome",
        icon: "Home",
        forceAnchor: true,
        onClick: () => {
          dismissPanel();
          navigate("/welcome");
        },
      },
     /* {
        name: "Subscribe",
        key: "subscribe",
        icon: "UserFollowed",
        forceAnchor: true,
        onClick: () => {
          dismissPanel();
          navigate("/subscribe");
        },
      },*/
    ];

    navItems.unshift({
      name: "Home",
      expandAriaLabel: "Expand Home menu section",
      collapseAriaLabel: "Collapse Home menu section",
      collapseByDefault: isAuth,
      links: linx,
    });

    return navItems;
  }, [allowRoute, dismissPanel, isAdmin, isAuth, isGroupAdmin, isLawReview, navigate, user.scholarsift.limits, user.scholarsift.paid]);

  const signInNav = () => {
    dismissPanel();
    navigate("/signin");
  };

  return (
    <>
      {isAuth && (
        <div className="collapse-nav">
          <div
            className="nav "
            aria-label="Open side menu"
            onClick={handleOpen}
          >
            <GlobalNavButtonIcon />{nMessages > 0 && <CounterBadge style={{marginRight: -10, marginTop: -10}} count={nMessages} color='danger' size='small' />}
          </div>
        </div>
      )}

      <div>
        <div
          className="global-nav-icon"
          id={darkMode ? "gni-dark" : undefined}
          aria-label="Open side menu"
          onClick={handleOpen}
        >
          <GlobalNavButtonIcon />
        </div>
      </div>
      <Panel
        isLightDismiss
        eventBubblingEnabled={true}
        isOpen={isOpen}
        onDismiss={dismissPanel}
        headerText="Menu"
        dismissPanel={true}
        headerTextProps={{
          style: darkMode
            ? {
                fontSize: 32,
                padding: 10,
                fontFamily: "'Outfit', sans-serif",
                color: "#FFF",
              }
            : {
                fontSize: 32,
                padding: 10,
                fontFamily: "'Outfit', sans-serif",
                color: "#4061d6",
              },
        }}
        closeButtonAriaLabel="Close"
        type={1}
      >
        <Nav groups={navGroups} styles={navStyle} selectedKey={null} />
        <Stack horizontal horizontalAlign="center" style={{ margin: 20 }}>
          {user.scholarsift.profile ? (
            <RectButton
              onClick={() => {
                signOut();
                dismissPanel();
              }}
              title="Sign Out"
              icon={<LeaveUserIcon style={{ marginLeft: 10 }} />}
            />
          ) : (
            <RectButton
              onClick={() => {
                signInNav();
                dismissPanel();
              }}
              title="Sign In"
              icon={<ContactIcon style={{ marginLeft: 10 }} />}
            />
          )}
        </Stack>
      </Panel>
    </>
  );
};

export default MenuPanel;
