//import { Calendar } from '@fluentui/react';
//import creds from '../../../creds.json';
import React from 'react';

const GroupSubscriptionInfo = props => {
    return  <div className="subscription-panel">
                <div className="subscription-title">No Group Subscription</div>
            </div>
}

export default React.memo(GroupSubscriptionInfo);